.section {
  @apply border-y border-solid border-black relative;
  margin-left: auto;
  margin-right: auto;
  
  &-label {
    position: absolute;
    left: 0;
    top: 0;
    width: rem(64);
    height: 100%;
    background-color: #fff;
    border-right: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    span {
      @apply text-17 font-medium;
      white-space: nowrap;
      transform: rotate(-90deg);
    }
  }

  &-anchor {
    position: absolute;
    top: rem(-80);
  }

  @media (max-width: $xs) {
    &-label {
      position: relative;
      width: auto;
      height: auto;
      border-right: none;
      display: block;
      
      span {
        @apply block mt-16 mb-8 text-16 tracking-wider;
        transform: none;
      }
    }
  }
}
.dropdown {
  &:first-child {
    .dropdown-inner {
      @apply border-t-2;
    }
  }

  &-inner {
    @apply border-b-2 border-solid border-black;
  }

  &__header {
    @apply text-21 font-medium py-8 px-10 flex justify-between cursor-pointer;

    &:after {
      @apply block;
      content: '+';

      .dropdown.opened & {
        content: '-';
      }
    }
  }

  &__body {
    @apply pl-10 pb-0 overflow-hidden;
    max-height: 0;
    transition: all .3s;

    p {
      @apply text-18 mt-0 mb-8;
    }
    
    ul {
      @apply pl-14;

      li {
        @apply text-18 flex mb-4;
  
        &:before {
          @apply block h-4 mr-5;
          content: '';
          width: rem(10);
          background-image: url('/img/flash.svg');
          background-size: 100% 100%;
        }
      }
    }

    .dropdown.opened & {
      @apply pb-4;
      max-height: rem(500);
    }
  }

  @media (max-width: $xs) {
    
    &__header {
      @apply text-16 py-7 px-2;
    }

    &__body {
      @apply pl-2;

      p {
        @apply text-14 mb-4;
      }

      ul {
        @apply pl-4;

        li {
          @apply text-14 mb-2;
  
          &:before {
            @apply h-3 mr-3;
          }
        }
      }

    }
  }
}
.pagination {
  @apply flex justify-center items-center text-18 py-24;
  color: #B9B9B9;

  @media (max-width: $xs) {
    @apply pt-14 pb-20;  
  }

  > * {
    @apply mx-2 px-1 py-1;
  }

  .current {
    @apply border-b border-solid border-black;
  }

  .current, .next, .prev {
    @apply text-black;
  }

  .next, .prev {
    @apply p-1 pb-2;

    svg {
      @apply w-1;
    }
  }
}
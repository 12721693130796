.team-slider {
  @apply flex items-center;

  &__body {
    @apply flex justify-between overflow-auto mx-5;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .arrow-btn {
    @apply shrink-0;

    &.hide {
      visibility: hidden;
    }
  }

  @media (max-width: $xs) {
    @apply block;

    &__body {
      @apply mx-0 px-8;
    }

    .arrow-btn {
      @apply w-11 h-11 mx-2;

      svg {
        @apply w-4;
      }
    }
  }
}

.team-card {
  @apply shrink-0 border-2 border-solid border-black;
  width: calc(33.33333% - 1.2rem);
  
  &:not(:last-child) {
    margin-right: 1.2rem;
  }

  &__image {
    @apply border-b-2 border-solid border-black relative;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__bottom {
    @apply px-5 py-4;

    &-title {
      @apply text-20 font-medium;
      
      span {
        @apply text-16 font-normal italic;
      }
    }

    &-content {
      @apply mt-3 text-14;
      line-height: 1.43;
    }
  }
  
  @media (max-width: $xs) {
    @apply w-3/4;

    &__image {
      &:after {
        content: none;
      }

      img {
        position: relative;
        height: rem(184);
      }
    }

    &__bottom {
      @apply px-4;

      &-title {
        @apply text-14;
        
        span {
          @apply text-10;
        }
      }

      &-content {
        @apply text-11 mt-2;
      }
    }
  }
  
  @media (max-width: $sm) {
    @apply w-2/3;
  }
}
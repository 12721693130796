.case-slider {
  @apply relative;

  &__body {
    @apply flex overflow-auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .arrow-btn {
    @apply mx-5 absolute z-1;
    top: 50%;
    transform: translateY(-50%);

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  @media (max-width: $xs) {
    .arrow-btn {
      @apply w-11 h-11 mx-2;

      svg {
        @apply w-4;
      }
    }
  }
}

.case-card {
  @apply overflow-hidden flex flex-col justify-end relative bg-main;

  &:not(:last-child) {
    @apply border-r-2 border-solid border-black;
  }
  
  @media (hover: hover) {
    &:hover {
      > img {
        @apply border-black;
        border-bottom: 2px solid;
        transform: translateY(-4.65rem);
        
      }
    }
  }

  > img {
    @apply absolute w-full;
    object-fit: cover;
    object-position: center;
    transition: all .3s;
  }

  &__bottom {
    @apply p-7 w-full flex items-center;

    > h4 {
      @apply text-18 flex;
    }

    svg {
      @apply w-4 ml-2 flex-shrink-0;
    }
  }

  @media (max-width: $sm) {
    > img {
      @apply relative border-black;
      border-bottom: 2px solid;
    }

    &__bottom {
      @apply p-6 flex-grow;

      h4 {
        @apply text-20;
      }
    }
  }
}
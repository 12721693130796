.banner {
  @apply bg-main;
  overflow: hidden;

  &-inner {
    @apply flex text-25 py-8 relative;
    
    h2 {
      @apply pl-20 inline-block flex-shrink-0;
    }
  }

  @media (max-width: $xs) {
    &-inner {
      @apply text-18;
    }
  }
}
@keyframes bannerSlide {
  0% {
    left: 0;
  }
  100% {
    left: var(--banner-offset);
  }
}
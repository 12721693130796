@tailwind base;
@tailwind components;

@import "common/_variables.scss";
@import "common/_scaling.scss";
@import "common/_global.scss";

@import "partials/header.scss";
@import "partials/footer.scss";
@import "partials/section.scss";
@import "partials/dropdown.scss";
@import "partials/cases.scss";
@import "partials/team.scss";
@import "partials/banner.scss";
@import "partials/article.scss";
@import "partials/pagination.scss";

@tailwind utilities;
.article {
  &-header {
    @apply flex justify-center border-b-2 border-solid border-black;

    &__image {
      @apply w-full;

      img {
        @apply w-full;
        max-height: 80vh;
        object-fit: cover;
        object-position: center;
      }

      figcaption {
        @apply py-7 border-t-2 border-solid border-black;
        padding-left: calc(50% - 40rem);
        padding-right: calc(50% - 40rem);
        font-size: rem(33);
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: .1em;
        line-height: 1.2;

        @media (max-width: $xs) {
          @apply text-30;
          letter-spacing: .05em;
        }
      }
    }
  }

  &-content {
    @apply pt-10 pb-16; 

    &__title {
      @apply flex justify-center text-center;

      &.lined h1 {
        @apply text-42 relative m-0;

        &:before {
          content: '';
          left: rem(-36);
          right: rem(-36);
          height: rem(18);
          position: absolute;
          top: 50%;
          transform: translateY(calc(-50% + .25rem));
          background-color: $main;
          z-index: -1;
        }
      }

      &.simple h1 {
        @apply text-35 m-0 w-2/3;

        @media (max-width: $xs) {
          @apply text-30;
        } 
      }

      @media (max-width: $xs) {
        &.lined h1, &.simple h1 {
          @apply text-30;
        }
      }
    }

    &__body {
      @media (max-width: $xs) {
        @apply px-4;
      }

      > *:first-child {
        @apply mt-10;
      }
      
      h2,h3,h4,h5,h6 {
        @apply mt-10;
      }
      h2 {
        @apply text-27;
        @media (max-width: $xs) {
          @apply text-23;
        }
      }
      h3 {
        @apply text-21;
        @media (max-width: $xs) {
          @apply text-20;
        }
      }
      h4 {
        @apply text-18;
      }
      h5 {
        @apply text-16;
      }
      h6 {
        @apply text-14;
      }

      p, ul, ol, figure {
        @apply text-18 mt-6 mb-10;
        line-height: 1.65;

        .page & {
          @apply text-14 mb-3;
        }

        &:last-child {
          @apply mb-0;
        }

        @media (max-width: $xs) {
          @apply text-14;

          .case & {
            @apply text-16;
          }
        }
      }

      ol, ul {
        @apply pl-10 mt-4;
        list-style: auto;
        li {
          @apply pl-2 mt-3;
        }
      }

      a {
        @apply link;
        text-decoration: underline;
      }

      figure {
        .post & {
          @media (min-width: $xsu) {
            @apply container-sm;
            width: 100vw;
            margin-left: 50%;
            transform: translateX(-50%);
          }
          
          @media (max-width: $xs) {
            @apply -mx-4;  
          }
        } 

      }
    }
  }

  &-more-link {
    @apply text-30 flex items-center;

    svg {
      @apply w-7 mx-5;
    }

    @media (max-width: $xs) {
      @apply text-23;

      svg {
        @apply w-5;
      }
    }
  }
}
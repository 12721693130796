@tailwind base;
@tailwind components;
/* Reset
/* ---------------------------------------------------------- */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

body {
  font-family: "Helvetica Neue", "Arial", "Roboto";
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.16;
}

p {
  @apply text-18 mt-10;
  font-weight: 400;
  line-height: 1.66;
}
@media (max-width: 639px) {
  p {
    @apply text-16 mt-6;
  }
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

html {
  box-sizing: border-box;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

b,
strong {
  font-weight: bold;
}

i,
em,
dfn {
  font-style: italic;
}

h1 {
  margin: 0.67em 0;
  font-size: 2em;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

mark {
  background-color: #fdffb6;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

kbd {
  padding: 3px 5px;
  font-family: var(--font-mono);
  font-size: 1.5rem;
  background: #f6f8fa;
  border: 1px solid rgba(124, 139, 154, 0.25);
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 rgba(124, 139, 154, 0.25);
}
@media (max-width: 639px) {
  kbd {
    font-size: 1.3rem;
  }
}

button,
input,
optgroup,
select,
textarea {
  margin: 0; /* 3 */
  color: inherit; /* 1 */
  font: inherit; /* 2 */
}

button {
  overflow: visible;
  border: none;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  cursor: pointer; /* 3 */
  -webkit-appearance: button; /* 2 */
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input {
  line-height: normal;
}

input:focus {
  outline: none;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

input[type=search] {
  box-sizing: content-box; /* 2 */
  -webkit-appearance: textfield; /* 1 */
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

legend {
  padding: 0; /* 2 */
  border: 0; /* 1 */
}

textarea {
  overflow: auto;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

td,
th {
  padding: 0;
}

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
[class*=container-] {
  @apply mx-auto;
}
@media (max-width: 1023px) {
  [class*=container-] {
    @apply px-4;
  }
}

.container-xs {
  max-width: 50rem;
}
.container-sm {
  max-width: 65rem;
}
.container-md {
  max-width: 76.25rem;
}
.container-lg {
  max-width: 84.375rem;
}
.container-xl {
  max-width: 90rem;
}
.container-2xl {
  max-width: 106.25rem;
}

.site-main {
  padding-top: 5rem;
}

.arrow-btn {
  @apply w-12 h-12 flex items-center justify-center
           bg-white border-2 border-solid border-black rounded-full;
}
@media (hover: hover) {
  .arrow-btn--prev:hover svg {
    transform: translateX(-0.2rem);
  }
  .arrow-btn--next:hover svg {
    transform: translateX(0.2rem);
  }
}
.arrow-btn svg {
  @apply w-4;
  transition: transform 0.3s;
}

.link {
  transition: all 0.3s;
}
.link:hover {
  @apply text-gray-600;
}
.link:hover.opacity {
  opacity: 0.6;
}

.header {
  @apply fixed left-0 top-0 w-full z-10 bg-white;
}
.header__nav {
  @apply flex;
}
.header__nav li {
  @apply text-16 mr-6 px-1 relative;
}
.header__nav li:before {
  @apply h-2 bg-main absolute left-0 right-full -z-1;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s;
}
.header__nav li.nav-current:before, .header__nav li:hover:before {
  @apply right-0;
}
@media (max-width: 1023px) {
  .header__nav li {
    @apply mr-4;
  }
  .header__nav li.nav-current:before {
    @apply h-3 bg-white;
  }
}
@media (max-width: 639px) {
  .header__toggle {
    @apply mr-3 z-30 smu:hidden;
  }
  .header__toggle.opened .top {
    transform: rotate(45deg);
  }
  .header__toggle.opened .middle {
    opacity: 0;
  }
  .header__toggle.opened .bottom {
    transform: rotate(-45deg);
  }
  .header__toggle > div {
    @apply w-5 bg-black;
    height: 0.125rem;
    transition: all 0.2s;
    transform-origin: 0 50%;
  }
  .header__toggle .top {
    transform-origin: 0 50%;
  }
  .header__toggle .middle {
    margin: 0.3125rem 0;
  }
  .header__nav {
    @apply flex-col justify-center items-center fixed w-screen h-screen left-0 top-0 bg-main z-20;
    transform: translateX(-100%);
    transition: transform 0.5s;
  }
  .header__nav.opened {
    transform: none;
  }
  .header__nav li {
    @apply text-30 font-medium mr-0 mb-8;
  }
}

.footer {
  @apply border-t-2 border-solid border-black;
}
.footer-link {
  @apply text-16 font-medium tracking-wider mb-5;
}
.footer__subscribe-form {
  @apply flex items-center mt-5 border-b-2 border-solid border-black;
}
.footer__subscribe-form input {
  @apply flex-grow text-10 leading-288;
}
.footer__subscribe-form input::placeholder {
  color: #9D9D9D;
}
.footer__subscribe-form button {
  @apply text-10 flex;
}
.footer__subscribe-result {
  @apply flex justify-center items-center text-16 font-bold mt-4;
}
.footer__subscribe-result svg {
  @apply h-6 mr-2 mb-1;
}

.section {
  @apply border-y border-solid border-black relative;
  margin-left: auto;
  margin-right: auto;
}
.section-label {
  position: absolute;
  left: 0;
  top: 0;
  width: 4rem;
  height: 100%;
  background-color: #fff;
  border-right: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.section-label span {
  @apply text-17 font-medium;
  white-space: nowrap;
  transform: rotate(-90deg);
}
.section-anchor {
  position: absolute;
  top: -5rem;
}
@media (max-width: 639px) {
  .section-label {
    position: relative;
    width: auto;
    height: auto;
    border-right: none;
    display: block;
  }
  .section-label span {
    @apply block mt-16 mb-8 text-16 tracking-wider;
    transform: none;
  }
}

.dropdown:first-child .dropdown-inner {
  @apply border-t-2;
}
.dropdown-inner {
  @apply border-b-2 border-solid border-black;
}
.dropdown__header {
  @apply text-21 font-medium py-8 px-10 flex justify-between cursor-pointer;
}
.dropdown__header:after {
  @apply block;
  content: "+";
}
.dropdown.opened .dropdown__header:after {
  content: "-";
}
.dropdown__body {
  @apply pl-10 pb-0 overflow-hidden;
  max-height: 0;
  transition: all 0.3s;
}
.dropdown__body p {
  @apply text-18 mt-0 mb-8;
}
.dropdown__body ul {
  @apply pl-14;
}
.dropdown__body ul li {
  @apply text-18 flex mb-4;
}
.dropdown__body ul li:before {
  @apply block h-4 mr-5;
  content: "";
  width: 0.625rem;
  background-image: url("/img/flash.svg");
  background-size: 100% 100%;
}
.dropdown.opened .dropdown__body {
  @apply pb-4;
  max-height: 31.25rem;
}
@media (max-width: 639px) {
  .dropdown__header {
    @apply text-16 py-7 px-2;
  }
  .dropdown__body {
    @apply pl-2;
  }
  .dropdown__body p {
    @apply text-14 mb-4;
  }
  .dropdown__body ul {
    @apply pl-4;
  }
  .dropdown__body ul li {
    @apply text-14 mb-2;
  }
  .dropdown__body ul li:before {
    @apply h-3 mr-3;
  }
}

.case-slider {
  @apply relative;
}
.case-slider__body {
  @apply flex overflow-auto;
}
.case-slider__body::-webkit-scrollbar {
  display: none;
}
.case-slider .arrow-btn {
  @apply mx-5 absolute z-1;
  top: 50%;
  transform: translateY(-50%);
}
.case-slider .arrow-btn--prev {
  left: 0;
}
.case-slider .arrow-btn--next {
  right: 0;
}
@media (max-width: 639px) {
  .case-slider .arrow-btn {
    @apply w-11 h-11 mx-2;
  }
  .case-slider .arrow-btn svg {
    @apply w-4;
  }
}

.case-card {
  @apply overflow-hidden flex flex-col justify-end relative bg-main;
}
.case-card:not(:last-child) {
  @apply border-r-2 border-solid border-black;
}
@media (hover: hover) {
  .case-card:hover > img {
    @apply border-black;
    border-bottom: 2px solid;
    transform: translateY(-4.65rem);
  }
}
.case-card > img {
  @apply absolute w-full;
  object-fit: cover;
  object-position: center;
  transition: all 0.3s;
}
.case-card__bottom {
  @apply p-7 w-full flex items-center;
}
.case-card__bottom > h4 {
  @apply text-18 flex;
}
.case-card__bottom svg {
  @apply w-4 ml-2 flex-shrink-0;
}
@media (max-width: 1023px) {
  .case-card > img {
    @apply relative border-black;
    border-bottom: 2px solid;
  }
  .case-card__bottom {
    @apply p-6 flex-grow;
  }
  .case-card__bottom h4 {
    @apply text-20;
  }
}

.team-slider {
  @apply flex items-center;
}
.team-slider__body {
  @apply flex justify-between overflow-auto mx-5;
}
.team-slider__body::-webkit-scrollbar {
  display: none;
}
.team-slider .arrow-btn {
  @apply shrink-0;
}
.team-slider .arrow-btn.hide {
  visibility: hidden;
}
@media (max-width: 639px) {
  .team-slider {
    @apply block;
  }
  .team-slider__body {
    @apply mx-0 px-8;
  }
  .team-slider .arrow-btn {
    @apply w-11 h-11 mx-2;
  }
  .team-slider .arrow-btn svg {
    @apply w-4;
  }
}

.team-card {
  @apply shrink-0 border-2 border-solid border-black;
  width: calc(33.33333% - 1.2rem);
}
.team-card:not(:last-child) {
  margin-right: 1.2rem;
}
.team-card__image {
  @apply border-b-2 border-solid border-black relative;
}
.team-card__image:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.team-card__image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.team-card__bottom {
  @apply px-5 py-4;
}
.team-card__bottom-title {
  @apply text-20 font-medium;
}
.team-card__bottom-title span {
  @apply text-16 font-normal italic;
}
.team-card__bottom-content {
  @apply mt-3 text-14;
  line-height: 1.43;
}
@media (max-width: 639px) {
  .team-card {
    @apply w-3/4;
  }
  .team-card__image:after {
    content: none;
  }
  .team-card__image img {
    position: relative;
    height: 11.5rem;
  }
  .team-card__bottom {
    @apply px-4;
  }
  .team-card__bottom-title {
    @apply text-14;
  }
  .team-card__bottom-title span {
    @apply text-10;
  }
  .team-card__bottom-content {
    @apply text-11 mt-2;
  }
}
@media (max-width: 1023px) {
  .team-card {
    @apply w-2/3;
  }
}

.banner {
  @apply bg-main;
  overflow: hidden;
}
.banner-inner {
  @apply flex text-25 py-8 relative;
}
.banner-inner h2 {
  @apply pl-20 inline-block flex-shrink-0;
}
@media (max-width: 639px) {
  .banner-inner {
    @apply text-18;
  }
}

@keyframes bannerSlide {
  0% {
    left: 0;
  }
  100% {
    left: var(--banner-offset);
  }
}
.article-header {
  @apply flex justify-center border-b-2 border-solid border-black;
}
.article-header__image {
  @apply w-full;
}
.article-header__image img {
  @apply w-full;
  max-height: 80vh;
  object-fit: cover;
  object-position: center;
}
.article-header__image figcaption {
  @apply py-7 border-t-2 border-solid border-black;
  padding-left: calc(50% - 40rem);
  padding-right: calc(50% - 40rem);
  font-size: 2.0625rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2;
}
@media (max-width: 639px) {
  .article-header__image figcaption {
    @apply text-30;
    letter-spacing: 0.05em;
  }
}
.article-content {
  @apply pt-10 pb-16;
}
.article-content__title {
  @apply flex justify-center text-center;
}
.article-content__title.lined h1 {
  @apply text-42 relative m-0;
}
.article-content__title.lined h1:before {
  content: "";
  left: -2.25rem;
  right: -2.25rem;
  height: 1.125rem;
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% + 0.25rem));
  background-color: #FFDE17;
  z-index: -1;
}
.article-content__title.simple h1 {
  @apply text-35 m-0 w-2/3;
}
@media (max-width: 639px) {
  .article-content__title.simple h1 {
    @apply text-30;
  }
}
@media (max-width: 639px) {
  .article-content__title.lined h1, .article-content__title.simple h1 {
    @apply text-30;
  }
}
@media (max-width: 639px) {
  .article-content__body {
    @apply px-4;
  }
}
.article-content__body > *:first-child {
  @apply mt-10;
}
.article-content__body h2, .article-content__body h3, .article-content__body h4, .article-content__body h5, .article-content__body h6 {
  @apply mt-10;
}
.article-content__body h2 {
  @apply text-27;
}
@media (max-width: 639px) {
  .article-content__body h2 {
    @apply text-23;
  }
}
.article-content__body h3 {
  @apply text-21;
}
@media (max-width: 639px) {
  .article-content__body h3 {
    @apply text-20;
  }
}
.article-content__body h4 {
  @apply text-18;
}
.article-content__body h5 {
  @apply text-16;
}
.article-content__body h6 {
  @apply text-14;
}
.article-content__body p, .article-content__body ul, .article-content__body ol, .article-content__body figure {
  @apply text-18 mt-6 mb-10;
  line-height: 1.65;
}
.page .article-content__body p, .page .article-content__body ul, .page .article-content__body ol, .page .article-content__body figure {
  @apply text-14 mb-3;
}
.article-content__body p:last-child, .article-content__body ul:last-child, .article-content__body ol:last-child, .article-content__body figure:last-child {
  @apply mb-0;
}
@media (max-width: 639px) {
  .article-content__body p, .article-content__body ul, .article-content__body ol, .article-content__body figure {
    @apply text-14;
  }
  .case .article-content__body p, .case .article-content__body ul, .case .article-content__body ol, .case .article-content__body figure {
    @apply text-16;
  }
}
.article-content__body ol, .article-content__body ul {
  @apply pl-10 mt-4;
  list-style: auto;
}
.article-content__body ol li, .article-content__body ul li {
  @apply pl-2 mt-3;
}
.article-content__body a {
  @apply link;
  text-decoration: underline;
}
@media (min-width: 640px) {
  .post .article-content__body figure {
    @apply container-sm;
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 639px) {
  .post .article-content__body figure {
    @apply -mx-4;
  }
}
.article-more-link {
  @apply text-30 flex items-center;
}
.article-more-link svg {
  @apply w-7 mx-5;
}
@media (max-width: 639px) {
  .article-more-link {
    @apply text-23;
  }
  .article-more-link svg {
    @apply w-5;
  }
}

.pagination {
  @apply flex justify-center items-center text-18 py-24;
  color: #B9B9B9;
}
@media (max-width: 639px) {
  .pagination {
    @apply pt-14 pb-20;
  }
}
.pagination > * {
  @apply mx-2 px-1 py-1;
}
.pagination .current {
  @apply border-b border-solid border-black;
}
.pagination .current, .pagination .next, .pagination .prev {
  @apply text-black;
}
.pagination .next, .pagination .prev {
  @apply p-1 pb-2;
}
.pagination .next svg, .pagination .prev svg {
  @apply w-1;
}

@tailwind utilities;
.header {
  @apply fixed left-0 top-0 w-full z-10 bg-white;

  &__nav {
    @apply flex;

    li {
      @apply text-16 mr-6 px-1 relative;

      &:before {
        @apply h-2 bg-main absolute left-0 right-full -z-1;
        content: '';
        top: 50%;
        transform: translateY(-50%);
        transition: all .2s;
      }
      &.nav-current, &:hover {
        &:before {
          @apply right-0;
        }
      }
    }
  }

  @media (max-width: $sm) {
    &__nav {
      li {
        @apply mr-4;

        &.nav-current {
          &:before {
            @apply h-3 bg-white;
          }
        }
      }
    }
  }

  @media (max-width: $xs) {
    &__toggle {
      @apply mr-3 z-30 smu:hidden;

      &.opened {
        .top {
          transform: rotate(45deg);
        }
        .middle {
          opacity: 0;
        }
        .bottom {
          transform: rotate(-45deg);
        }
      }

      > div {
        @apply w-5 bg-black;
        height: rem(2);
        transition: all .2s;
        transform-origin: 0 50%;
      }

      .top {
        transform-origin: 0 50%;
      }

      .middle {
        margin: rem(5) 0;
      }
    }

    &__nav {
      @apply flex-col justify-center items-center fixed w-screen h-screen left-0 top-0 bg-main z-20;
      transform: translateX(-100%);
      transition: transform .5s;

      &.opened {
        transform: none;
      }
      
      li {
        @apply text-30 font-medium mr-0 mb-8;
      }
    }
  }
}
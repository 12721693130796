.footer {
  @apply border-t-2 border-solid border-black;

  &-link {
    @apply text-16 font-medium tracking-wider mb-5;
  }

  &__subscribe {
    &-form {
      @apply flex items-center mt-5 border-b-2 border-solid border-black;

      input {
        @apply flex-grow text-10 leading-288;

        &::placeholder {
          color: #9D9D9D;
        }
      }

      button {
        @apply text-10 flex;

      }
    }
    
    &-result {
      @apply flex justify-center items-center text-16 font-bold mt-4;

      svg {
        @apply h-6 mr-2 mb-1;
      }
    }
  }
}
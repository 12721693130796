/* Reset
/* ---------------------------------------------------------- */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}
body {
    font-family: 'Helvetica Neue', 'Arial', 'Roboto';
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    line-height: 1.16;
}
p {
    @apply text-18 mt-10;
    font-weight: 400;
    line-height: 1.66;

    @media (max-width: $xs) {
        @apply text-16 mt-6;
    }
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
img {
    display: block;
    max-width: 100%;
    height: auto;
}
html {
    box-sizing: border-box;
    font-family: sans-serif;

    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
a {
    background-color: transparent;
}
a:active,
a:hover {
    outline: 0;
}
b,
strong {
    font-weight: bold;
}
i,
em,
dfn {
    font-style: italic;
}
h1 {
    margin: 0.67em 0;
    font-size: 2em;
}
small {
    font-size: 80%;
}
sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
img {
    border: 0;
}
svg:not(:root) {
    overflow: hidden;
}
mark {
    background-color: #fdffb6;
}
code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}
kbd {
    padding: 3px 5px;
    font-family: var(--font-mono);
    font-size: 1.5rem;
    background: #f6f8fa;
    border: 1px solid rgba(124, 139, 154, 0.25);
    border-radius: 6px;
    box-shadow: inset 0 -1px 0 rgba(124, 139, 154, 0.25);
    @media (max-width: $xs) {
        font-size: 1.3rem;
    }
}
button,
input,
optgroup,
select,
textarea {
    margin: 0; /* 3 */
    color: inherit; /* 1 */
    font: inherit; /* 2 */
}
button {
    overflow: visible;
    border: none;
}
button,
select {
    text-transform: none;
}
button,
html input[type="button"],
/* 1 */
input[type="reset"],
input[type="submit"] {
    cursor: pointer; /* 3 */

    -webkit-appearance: button; /* 2 */
}
button[disabled],
html input[disabled] {
    cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}
input {
    line-height: normal;
}
input:focus {
    outline: none;
}
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
input[type="search"] {
    box-sizing: content-box; /* 2 */

    -webkit-appearance: textfield; /* 1 */
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
legend {
    padding: 0; /* 2 */
    border: 0; /* 1 */
}
textarea {
    overflow: auto;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
td,
th {
    padding: 0;
}

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
[class*="container-"] {
    @apply mx-auto;

    @media (max-width: $sm) {
        @apply px-4;
    }
}

.container {
    &-xs {
        max-width: rem(800);
    }
    &-sm {
        max-width: rem(1040);
    }
    &-md {
        max-width: rem(1220);
    }
    &-lg {
        max-width: rem(1350);
    }
    &-xl {
        max-width: rem(1440);
    }
    &-2xl {
        max-width: rem(1700);
    }
}

.site-main {
    padding-top: rem(80);
}

.arrow-btn {
    @apply w-12 h-12 flex items-center justify-center
           bg-white border-2 border-solid border-black rounded-full;

    @media (hover: hover) {
        &--prev:hover svg {
            transform: translateX(-.2rem)
        }
        &--next:hover svg {
            transform: translateX(.2rem)
        }
    }

    svg {
        @apply w-4;
        transition: transform .3s;
    }
}

.link {
    transition: all .3s;

    &:hover {
        @apply text-gray-600;

        &.opacity {
            opacity: .6;
        }
    }
}

// .site-main {
//     @apply mx-auto;
//     max-width: $maxContainer;

//     @media (min-width: calc($maxContainer + 1px)) {
//         @apply border-x-2 border-solid border-black;
//     }
// }
// SASS MEDIA SCREEN SIZES
$sm: 1023px;
$xs: 639px;
$xsu: 640px;
$smu: 1024px;

$maxContainer: 1700px;

// SASS COLORS
$main: #FFDE17;

